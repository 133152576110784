import React, { FC } from 'react'
import { useRouteMatch } from 'react-router'
import { BannerPopUpRulesCookie } from 'components/banner/BannerPopUpRulesCookie'
import { LocaleSwitch } from 'components/system/LocaleSwitch'
import { createModalRoutes } from 'routes/layoutModalRoutes'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

// так как мы поддерживаем ie11 и этот код не пройдет через полифиллы поэтому приходиться писать на es5
// TODO IE11 больше не поддерживаем, надо написать обычным образом
const createMarkup = () => ({
  __html: `
        document.addEventListener("DOMContentLoaded", function(event) {
        var videoContainer = document.getElementById('video-container');
        if(videoContainer) {
           var videoList = [].slice.call(videoContainer.querySelectorAll('video'));
           var counterVideo = 0;
           videoList.forEach(function (videoPlayer) {
              var videoArray = videoList;
              videoPlayer.addEventListener('ended', function(event) {
                videoArray[counterVideo].style.display = 'none';
                counterVideo = counterVideo === 3 ? counterVideo - 3 : ++counterVideo;
                videoArray[counterVideo].currentTime = 0;
                videoArray[counterVideo].style.display = 'block';
                videoArray[counterVideo].play().catch(console.error);
              });
           });
        }
  });`,
})

export const VideoModalSwitch: FC = () => {
  const { authorized } = useShallowEqualSelector(
    ({ authorizationReducer: { authorized } }) => ({
      authorized,
    })
  )

  const match = useRouteMatch()

  return (
    <>
      <BannerPopUpRulesCookie />
      <LocaleSwitch
        matchPath={match?.url}
        routes={createModalRoutes(authorized)}
      />
      <script dangerouslySetInnerHTML={createMarkup()} />
    </>
  )
}
