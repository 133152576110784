import React, { FC } from 'react'
import { StyledBackground, StyledVideo } from './VideoLayout.styled'
import CirceBoldWoff2 from 'components/fonts/circe/Circe-Bold.woff2'
import BackgroundStartImageFirst from 'components/page/Promo/InviteFriendPromo/Image/background-start-first.jpg'
import BackgroundStartVideoFirstWebm from 'components/page/Promo/InviteFriendPromo/Video/start-first-video.webm'
import BackgroundStartVideoFirstMp4 from 'components/page/Promo/InviteFriendPromo/Video/start-first-video.mp4'
import BackgroundStartImageSecond from 'components/page/Promo/InviteFriendPromo/Image/background-start-second.jpg'
import BackgroundStartVideoSecondWebm from 'components/page/Promo/InviteFriendPromo/Video/start-second-video.webm'
import BackgroundStartVideoSecondMp4 from 'components/page/Promo/InviteFriendPromo/Video/start-second-video.mp4'
import BackgroundStartImageThird from 'components/page/Promo/InviteFriendPromo/Image/background-start-third.jpg'
import BackgroundStartVideoThirdWebm from 'components/page/Promo/InviteFriendPromo/Video/start-third-video.webm'
import BackgroundStartVideoThirdMp4 from 'components/page/Promo/InviteFriendPromo/Video/start-third-video.mp4'
import BackgroundStartImageFourth from 'components/page/Promo/InviteFriendPromo/Image/background-start-fourth.jpg'
import BackgroundStartVideoFourthWebm from 'components/page/Promo/InviteFriendPromo/Video/start-fourth-video.webm'
import BackgroundStartVideoFourthMp4 from 'components/page/Promo/InviteFriendPromo/Video/start-fourth-video.mp4'
import { CirceBoldFont } from 'components/fonts/circe/CirceBold'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

const VideoList: FC = () => (
  <div id={'video-container'}>
    <StyledVideo
      autoPlay
      poster={BackgroundStartImageFirst}
      playsInline
      muted
      controls={false}
      preload="none"
    >
      <source src={BackgroundStartVideoFirstWebm} type="video/webm" />
      <source src={BackgroundStartVideoFirstMp4} type="video/mp4" />
    </StyledVideo>
    <StyledVideo
      style={{
        display: 'none',
      }}
      autoPlay
      poster={BackgroundStartImageSecond}
      playsInline
      muted
      controls={false}
      preload="none"
    >
      <source src={BackgroundStartVideoSecondWebm} type="video/webm" />
      <source src={BackgroundStartVideoSecondMp4} type="video/mp4" />
    </StyledVideo>
    <StyledVideo
      style={{
        display: 'none',
      }}
      autoPlay
      poster={BackgroundStartImageThird}
      playsInline
      muted
      controls={false}
      preload="none"
    >
      <source src={BackgroundStartVideoThirdWebm} type="video/webm" />
      <source src={BackgroundStartVideoThirdMp4} type="video/mp4" />
    </StyledVideo>
    <StyledVideo
      style={{
        display: 'none',
      }}
      autoPlay
      poster={BackgroundStartImageFourth}
      playsInline
      muted
      controls={false}
      preload="none"
    >
      <source src={BackgroundStartVideoFourthWebm} type="video/webm" />
      <source src={BackgroundStartVideoFourthMp4} type="video/mp4" />
    </StyledVideo>
  </div>
)

export const VideoBackground: FC = () => {
  const { web } = useShallowEqualSelector(({ systemReducer: { web } }) => ({
    web,
  }))
  return (
    <>
      <link
        rel="preload"
        href={`${CirceBoldWoff2}`}
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        crossOrigin="anonymous"
        as="image"
        href={BackgroundStartImageFirst}
      />
      {web && <VideoList />}
      <StyledBackground />
      <CirceBoldFont />
    </>
  )
}
