import styled, { css } from 'styled-components'
import { maxDesktopSize, media, sizes } from 'components/presentational'
import { ModalCss } from 'components/layout/ModalLayout/ModalLayout.styled'
import { OrdinaryButton } from 'components/presentational/button'
import { disabledIconColor, fontFamily, smallFontSize } from 'common/constants'
import { Sizes } from 'components/layout/Sizes'
import { FontSize } from 'common-constants/sizes'
import { Colors } from 'common-constants/colors'

export const Body = styled.main`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
  align-items: flex-start;

  ${media.phone`
    flex-direction: column;
  `};
`
export const ActionBlock = styled.div`
  display: flex;
  margin-top: 40px;
  ${media.phone`
    margin-top: 0;
  `}
`
export const StyledLetter = styled.div`
  ${ModalCss};
  margin: auto 0;
`

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 40px;
  box-sizing: border-box;
  font-family: ${fontFamily};
  align-items: center;

  ${media.phone`
    padding: 0;    
  `};
`
export const StyledVideo = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  ${(props) => props.theme.left}: 0;
  top: 0;
  z-index: -1;
  object-fit: cover;
  background-color: #ecaf62;
`
export const StyledGroupLink = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  ${media.phone`
    padding: 0;
    flex-wrap: wrap;
  `};
`

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
export const EnterButton = styled(OrdinaryButton)`
  &&& {
    min-width: 120px;
    padding-top: 11px;
    padding-bottom: 12px;

    ${media.phone`
      font-size: 10px;
      padding-top: 8px;
      padding-bottom: 9px;
      min-width: 80px;
      border-radius: 5px;    
  `};
  }
`
export const FooterContainer = styled.div`
  font-family: sans-serif;
  font-size: ${smallFontSize}px;
  color: ${Colors.noteColor};
  text-align: center;
`

export const StyledBackground = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  ${(props) => props.theme.left}: 0;
  top: 0;
  z-index: -1;
`

export const StyledWrapperCopyright = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  color: ${disabledIconColor};
`
export const StyledAppLink = styled.div`
  display: flex;
  justify-content: center;
  ${media.phone`
    margin-bottom: 20px;
  `};
`
const WidthLimitCss = css`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-width: ${maxDesktopSize}px;
  width: 100%;

  @media (min-width: ${sizes.phone + 1}px) {
    padding: 0;
  }

  flex-grow: 1;
`
export const StyledWidthLimit = styled.div`
  ${WidthLimitCss}
  ${media.phone`
    margin: 0;
    padding: 0 15px;
  `};
`
export const StyledModalWidthLimit = styled.div`
  ${WidthLimitCss}
`
