import { goBackToBaseUrlAction } from 'actions/route/routeAction'
import { LogoLink } from 'components/layout/MambaLayout/LogoLink'
import {
  EnterButton,
  Header,
} from 'components/layout/VideoLayout/VideoLayout.styled'
import { getLoginPath } from 'components/page/Boarding/Login.functions'
import { LogoIndex } from 'components/presentational/Logo/LogoIndex'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useLayoutContext } from 'hooks/useLayoutContext'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { indexPath } from 'components/paths'
import { push } from 'functions/router'

export const VideoHeader: FC = () => {
  const dispatch = useDispatch()
  const { partnerId } = useShallowEqualSelector(
    ({ systemReducer: { partnerId } }) => ({
      partnerId,
    })
  )
  const { baseUrl } = useLayoutContext()

  const handleClick = (event: MouseEvent) => {
    event.preventDefault()
    /** По-идее логика клика по логотипу идет на главную */
    dispatch(push(indexPath))
  }

  return (
    <Header>
      <LogoLink to={indexPath} onClick={handleClick}>
        <LogoIndex />
      </LogoLink>
      <EnterButton
        to={mergeAllUrls(baseUrl, getLoginPath(partnerId))}
        data-name={'login-action'}
      >
        <FormattedMessage id={'app.enter'} defaultMessage={'Войти'} />
      </EnterButton>
    </Header>
  )
}
