import {
  FooterContainer,
  StyledAppLink,
  StyledGroupLink,
  StyledWrapperCopyright,
} from 'components/layout/VideoLayout/VideoLayout.styled'
import {
  FooterLink,
  LangWrapper,
  LinkCompany,
  SupportFooterLink,
} from 'components/block/Footer'
import { RouterLink } from 'components/presentational/link'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { shortcutListPath } from 'components/page/Boarding/boarding.paths'
import { FormattedMessage } from 'react-intl'
import { appConfidentialityPath } from 'components/paths'
import { supportFormPath } from 'components/page/Boarding/SupportForm/SupportForm.paths'
import DownloadApp from 'components/page/Boarding/DownloadApp'
import { threeFeaturesLandingType } from 'components/landing/landing.constants'
import { DownloadAppDesktop } from 'components/presentational/DownloadApp/DownloadAppDesktop'
import React, { FC } from 'react'
import { useLayoutContext } from 'hooks/useLayoutContext'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { LangSelect } from 'components/container/LangSelect'
import { FooterSeoLinks } from 'components/page/FooterSeoLinks/FooterSeoLinks'
import { LinkWrapper } from 'components/block/Footer/LinkWrapper'
import { Copyright } from 'components/block/Footer/Copyright'
import { DEFAULT_LOCALE, isLoveMailru } from 'common/constants'
import { RecommendationTechnologiesLink } from 'components/block/RecommendationTechnologiesLink/RecommendationTechnologiesLink'
import { useLocale } from 'hooks/useLocale'

export const VideoFooter: FC<{
  showAppLinks?: boolean
  showLinks?: boolean
}> = ({ showAppLinks = true, showLinks = true }) => {
  const { baseUrl } = useLayoutContext()
  const locale = useLocale()
  const { web, touch, partnerId } = useShallowEqualSelector(
    ({ systemReducer: { web, touch, partnerId } }) => ({
      web,
      touch,
      partnerId,
    })
  )

  const isShowFooterLinks = !isLoveMailru(partnerId)

  return (
    <FooterContainer>
      {locale === DEFAULT_LOCALE && (
        <RecommendationTechnologiesLink style={{ marginBottom: '20px' }} />
      )}

      <StyledWrapperCopyright>
        <Copyright />
      </StyledWrapperCopyright>
      {isShowFooterLinks && (
        <StyledGroupLink>
          <FooterSeoLinks typeLink="desktop" />
        </StyledGroupLink>
      )}
      {showLinks && (
        <StyledGroupLink>
          <LinkCompany />
          <LinkWrapper>
            <RouterLink
              to={mergeAllUrls(baseUrl, shortcutListPath)}
              data-name={'footer-shortcuts-action'}
            >
              <FormattedMessage id={'boarding.shortcuts_title'} />
            </RouterLink>
          </LinkWrapper>

          <FooterLink
            to={mergeAllUrls(baseUrl, appConfidentialityPath)}
            data-name={'footer-confidentiality-action'}
          >
            <FormattedMessage id={'boarding.confidentiality'} />
          </FooterLink>
          <SupportFooterLink
            to={mergeAllUrls(baseUrl, supportFormPath)}
            data-name={'footer-support-form-action'}
          >
            <FormattedMessage id={'app.support_chat'} />
          </SupportFooterLink>
          <LangWrapper>
            <LangSelect />
          </LangWrapper>
        </StyledGroupLink>
      )}
      {showAppLinks && (
        <StyledAppLink>
          {touch && (
            <DownloadApp
              onboarding={threeFeaturesLandingType}
              withText={false}
            />
          )}
          {web && (
            <DownloadAppDesktop
              appStore={{ width: 100, height: 27 }}
              playStore={{ width: 105, height: 24 }}
            />
          )}
        </StyledAppLink>
      )}
    </FooterContainer>
  )
}
