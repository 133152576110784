import React, { FC } from 'react'
import { isMamba } from 'common/constants'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { DownLoadAppDesktopIcon } from 'components/presentational/DownloadApp/DownLoadAppDesktopIcon'
import { appMambaHuaweiAppGallery } from 'components/presentational/DownloadApp/DownLoadAppDesktop.constants'
import { Container, Image } from './DownLoadAppDesktop.styled'

interface Icon {
  width: number
  height: number
}

export interface DownloadAppDesktopProps {
  playStore?: Icon
  appStore?: Icon
  playStoreLink?: string
  appStoreLink?: string
}

export const DownloadAppDesktop: FC<DownloadAppDesktopProps> = ({
  playStore = { width: 89, height: 19 },
  appStore = { width: 76, height: 21 },
  playStoreLink,
  appStoreLink,
}) => {
  const { partnerId } = useShallowEqualSelector(
    ({ systemReducer: { partnerId } }) => ({
      partnerId,
    })
  )

  const isMambaPartner = isMamba(partnerId)

  return (
    <Container>
      <DownLoadAppDesktopIcon
        playStore={playStore}
        appStore={appStore}
        playStoreLink={playStoreLink}
        appStoreLink={appStoreLink}
      />
      {isMambaPartner && (
        <a href={appMambaHuaweiAppGallery} target={'_blank'} rel="noreferrer">
          <Image
            src={require('./huawei-app-gallery.svg')}
            {...appStore}
            alt={'Huawei AppGallery'}
          />
        </a>
      )}
    </Container>
  )
}
